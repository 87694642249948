const NoPositionFound = () => {
  return (
    <div>
      <p>
        No positions found! If you wish to apply for a position, we encourage
        you to contact us at{' '}
        <a href="mailto:recruitment@tasmanianberries.com.au">
          <span className="contact-2">recruitment@tasmanianberries.com.au</span>
        </a>
        .
      </p>
    </div>
  );
};
export default NoPositionFound;
