import React, { useState, useEffect } from "react";
import { api_url } from "../services/API";
import NoPositionFound from "./NoPositionFound";
import { BlobButton } from "./Button";

function SeasonalPositions() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch(api_url)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result.hw_data);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <NoPositionFound />;
  } else if (!isLoaded) {
    return (
      <div className="position-loader d-flex flex-column align-items-center justify-content-center">
        Loading...
        <div className="spinner">
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
        </div>
      </div>
    );
  } else if (items.length === 0) {
    return <NoPositionFound />;
  } else {
    return (
      <div className="buttons-wrap animate__animated  animate__bounceInRight">
        {items.map((item) => (
          <BlobButton key={item.title} title={item.title} link={item.link} />
        ))}
      </div>
    );
  }
}

export default SeasonalPositions;
