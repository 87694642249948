import { WorkWithUsButton } from "../utilities/Button";

const textArray = [
  "We Value: Safety, Passion, Openness, Respect, and Teamwork",
];
const typingDelay = 100;
const newTextDelay = 500; // Delay between current and next text
let textArrayIndex = 0;
let charIndex = 0;

function type() {
  const typedTextSpan = document.querySelector(".typed-text");
  const cursorSpan = document.querySelector(".cursor");
  if (charIndex < textArray[textArrayIndex].length) {
    if (!cursorSpan.classList.contains("typing"))
      cursorSpan.classList.add("typing");
    typedTextSpan.textContent += textArray[textArrayIndex].charAt(charIndex);
    charIndex++;
    setTimeout(type, typingDelay);
  } else {
    cursorSpan.classList.remove("typing");
  }
}

document.addEventListener("DOMContentLoaded", function () {
  // On DOM Load initiate the effect
  if (textArray.length) setTimeout(type, newTextDelay + 500);
});

function Banner() {
  return (
    <section id="banner">
      <div className="ftco-banner vh-100 ">
        <div className="container h-100 text-center d-flex flex-column justify-content-center align-items-center">
          <div className="row banner-text text-center">
            <h1 className="text-white mb-0">Tasmanian Berries</h1>
            <div className="typing-container ">
              <p className="mt-2 mb-4 py-1 px-3 ">
                <span className="typed-text"></span>
                <a
                  href="/work-with-us"
                  target="_blank"
                  className="cursor d-none d-lg-inline"
                >
                  <span className="ms-2 fa fa-chevron-circle-right text-white opacity-75"></span>
                </a>
              </p>
            </div>
            <p className="animate__animated animate__bounceInUp d-flex d-lg-none flex-row flex-wrap align-items-center justify-content-center">
              <WorkWithUsButton />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Banner;
