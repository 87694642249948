import React, { useState, useEffect } from "react";
import { auth } from "../services/firebaseConfig";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "../services/firebaseConfig";
import { collection, query, getDocs } from "firebase/firestore";

const AppLink = (props) => {
  const userCount = props.userList.filter(
    (user) => user.appNo === props.appNo
  ).length;

  if (userCount > 90) {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className={`${
          props.user.appNo === props.appNo ? "d-flex" : "d-none"
        } flex-column align-items-center px-4 py-3 text-bg-dark bg-gradient rounded-5 `}
        onClick={() => {
          props.onSelectApp(props.appNo);
        }}
      >
        <div className="h4 m-0">
          {props.user.appNo ? "Go to App" : `Link ${props.appNo}`}
          <i className="fa fa-arrow-circle-right ms-2" aria-hidden="true"></i>
        </div>
        <div>{userCount} users</div>
      </a>
    );
  } else {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer"
        className={`${
          props.user.appNo === 0 || props.user.appNo === props.appNo
            ? "d-flex"
            : "d-none"
        } flex-column align-items-center px-4 py-3 text-bg-dark rounded-5 `}
        onClick={() => {
          props.onSelectApp(props.appNo);
        }}
      >
        <div className="h4 m-0">
          {props.user.appNo ? "Go to App" : `Link ${props.appNo}`}
          <i className="fa fa-arrow-circle-right ms-2" aria-hidden="true"></i>
        </div>
        <div>{userCount} users</div>
      </a>
    );
  }
};

const SWAppHomepage = () => {
  const [loading, setLoading] = useState(true);
  const [userList, setUserList] = useState([]);

  // login states
  const [loggedIn, setLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhoto, setUserPhoto] = useState("");

  const [user, setUser] = useState({ appNo: 0 });

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        document
          .getElementById("firebaseui-auth-container")
          .classList.add("d-none");
        document.getElementById("attention-note").classList.add("d-none");

        setLoggedIn(true);
        setUserEmail(authResult.user.email);
        setUserName(authResult.user.displayName);
        setUserPhoto(authResult.user.photoURL);

        console.log("authResult.user", authResult.user);

        const userInfo = userList.filter(
          (user) => user.email === authResult.user.email
        );

        if (userInfo.length > 0) {
          setUser(userInfo[0]);
        }

        return true;
      },
      uiShown: function () {},
    },
    signInFlow: "popup",
    signInSuccessUrl: "",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };
  // Loading firebase log in data
  useEffect(() => {
    const getWorkRecordData = async () => {
      // console.log("Getting data...");
      const q = query(collection(db, "sw-app"));
      const querySnapshot = await getDocs(q);
      const tempUserList = [];
      querySnapshot.forEach((doc) => {
        const record = doc.data();
        // console.log("record", record);
        tempUserList.push({
          email: record.email,
          name: record.name,
          appNo: record.appNo,
        });
      });
      // console.log("tempUserList", tempUserList);
      setUserList(tempUserList);
      setLoading(false);
    };
    getWorkRecordData();
  }, []);

  // If loading is done then show Google Firebase login screen
  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);
  }, [loading]);

  function onSelectApp(appNo) {
    if (userEmail) {
      setDoc(doc(db, "sw-app", userEmail), {
        name: userName,
        email: userEmail,
        appNo: appNo,
        timestamp: new Date(),
      });
      document.getElementById("app-selection").classList.add("d-none");
      document.getElementById("thank-you").classList.remove("d-none");
    }
  }

  return (
    <div className="fluid-container min-vh-100 d-flex flex-column justify-content-between align-items-center">
      <div className="w-100 text-bg-blackberry bg-gradient mb-3 py-3 shadow">
        <h1 className="text-center h2 m-0">Tasmanian Berries</h1>
      </div>
      {loading ? (
        <div className="position-loader d-flex flex-column align-items-center justify-content-center">
          Loading...
          <div className="spinner">
            <div className="rect1"></div>
            <div className="rect2"></div>
            <div className="rect3"></div>
            <div className="rect4"></div>
            <div className="rect5"></div>
          </div>
        </div>
      ) : (
        <div>
          <div
            id="firebaseui-auth-container"
            className="d-flex flex-column align-items-center justify-content-center p-4 m-3"
          >
            <div>
              <h2 className="text-center">
                <i class="fa fa-users me-3" aria-hidden="true"></i>
                SEASONAL WORKER APP
              </h2>
            </div>
            <div className="text-center px-5">
              Please sign in using your Google account.
            </div>
          </div>

          <div
            id="attention-note"
            className="d-flex flex-column flex-md-row justify-content-center my-3 p-3"
          >
            <div
              class="alert alert-danger col-12 col-md-6 col-lg-4"
              role="alert"
            >
              <h5 class="alert-heading">
                <i class="fa fa-exclamation-circle me-2" aria-hidden="true"></i>
                ATTENTION
              </h5>
              <p className="">
                Your access will get blocked if you open the App inside a chat
                app such as Line chat or Facebook Messenger. You need to open a
                web browser and try signing in from there.
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center col-12 col-md-6 col-lg-4">
              <img
                className="instruction-img"
                src="./images/instructions/blocked.png"
                alt="step-3"
              />
            </div>
          </div>
        </div>
      )}
      {loggedIn ? (
        <div className="mx-1 d-flex flex-column align-items-center justify-content-center rounded-4 bg-light">
          {/* <div className="d-flex align-items-center justify-content-center my-3 ">
            <div className="d-flex flex-wrap align-items-center gap-4">
              <div>
                <img
                  src={userPhoto}
                  alt=""
                  className="border border-light border-opacity-25 rounded bg-blueberry"
                />
              </div>
              <div className="d-flex flex-column">
                <div className="fs-3">
                  <i className="fa fa-user me-2" aria-hidden="true"></i>
                  {userName}
                </div>
                <div className="">{userEmail}</div>
              </div>
            </div>
          </div> */}
          <div id="app-selection" className="p-4 my-3 mx-1 rounded-4 bg-light">
            <div className="d-flex flex-column align-items-center justify-content-center mb-3">
              <div className="fs-3 text-black">
                <i className="fa fa-user me-2" aria-hidden="true"></i>
                {userName}
              </div>
              <div>({userEmail})</div>
            </div>
            <div className="text-center alert alert-info">
              <i class="fa fa-info me-2" aria-hidden="true"></i>

              {user.appNo === 0
                ? "Please select one of the links below to access the Seasonal Worker App."
                : "Welcome back, here is your link to the Seasonal Worker App."}
            </div>
            <hr />
            <div className="d-flex flex-wrap justify-content-center gap-3">
              <AppLink
                href={
                  "https://script.google.com/macros/s/AKfycbxRDWvBvBfPMw_-tWWGTBV0gftiWo6Jp15wplm9d-koYghARO-hOnAlB4JdkkMhJvG4ZA/exec"
                }
                user={user}
                appNo={1}
                onSelectApp={onSelectApp}
                userList={userList}
              />
              <AppLink
                href={
                  "https://script.google.com/macros/s/AKfycbyLmNxElSwqwhoJjPB2UN5VndLkStNDZkWiJGmakDRi48uMUHPMP6UqgwEvAkRBRRKWWA/exec"
                }
                user={user}
                appNo={2}
                onSelectApp={onSelectApp}
                userList={userList}
              />
              <AppLink
                href={
                  "https://script.google.com/macros/s/AKfycbyrRSSHTo7Z8_xnjKrSJtRqKGrJ43w5p_iCqEQpEIohhH2wKE-971IpjMQsrMroSWyz9A/exec"
                }
                user={user}
                appNo={3}
                onSelectApp={onSelectApp}
                userList={userList}
              />
              <AppLink
                href={
                  "https://script.google.com/macros/s/AKfycbzZNjhCn066gpsOGBA-RAhyS5oMtnmG35ClwOVVjJ0JcJ1zevXFVIGAwWbPYn8jVp-r/exec"
                }
                user={user}
                appNo={4}
                onSelectApp={onSelectApp}
                userList={userList}
              />
              <AppLink
                href={
                  "https://script.google.com/macros/s/AKfycbwQV0hRpnpF-OrOOMfCRYQjAgWuJjOx3yKZflxLRmzTMoncnwiASjBQid6HR9pqVhUyQw/exec"
                }
                user={user}
                appNo={5}
                onSelectApp={onSelectApp}
                userList={userList}
              />
            </div>
          </div>
          <div id="thank-you" className="d-none m-1 m-md-3">
            <div className="d-flex justify-content-center">
              <div className="text-center alert alert-success w-fit">
                <small>
                  <i class="fa fa-check-circle me-2" aria-hidden="true"></i>
                  Thank you! Please contact ha.nguyen@tasmanianberries.com.au
                  for any inquiries.
                </small>
              </div>
            </div>
          </div>
          <div id="app-instructions" className="py-4 my-3 mx-1 rounded-4">
            <div className="">
              <h2 className="text-center">APP INSTRUCTIONS</h2>
              <div className="d-flex justify-content-center">
                <div className="text-center alert alert-warning w-fit">
                  <small>
                    <i
                      class="fa fa-exclamation-circle me-2"
                      aria-hidden="true"
                    ></i>
                    You need to grant permissions before using the app.
                  </small>
                </div>
              </div>
              <hr />
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center justify-content-center mb-5 ">
                <h3 className="fs-4 text-center mb-3 instruction-step">
                  Step 1: Click "Review Permissions"
                </h3>
                <img
                  className="instruction-img"
                  src="./images/instructions/1.png"
                  alt="step-1"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center mb-5 ">
                <h3 className="fs-4 text-center mb-3 instruction-step">
                  Step 2: Choose an account
                </h3>
                <img
                  className="instruction-img"
                  src="./images/instructions/2.png"
                  alt="step-2"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center mb-5 ">
                <h3 className="fs-4 text-center mb-3 instruction-step">
                  Step 3: Click "Advanced"
                </h3>
                <img
                  className="instruction-img"
                  src="./images/instructions/3.png"
                  alt="step-3"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center mb-5 ">
                <h3 className="fs-4 text-center mb-3 instruction-step">
                  Step 4: Click "Go to Seasonal Worker App"
                </h3>
                <img
                  className="instruction-img"
                  src="./images/instructions/4.png"
                  alt="step-4"
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center mb-5 ">
                <h3 className="fs-4 text-center mb-3 instruction-step">
                  Step 5: Click "Allow"
                </h3>
                <img
                  className="instruction-img"
                  src="./images/instructions/5.png"
                  alt="step-5"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="text-center p-3 border-top">
        <small>
          Please contact{" "}
          <a href="mailto:ha.nguyen@tasmanianberries.com.au">
            ha.nguyen@tasmanianberries.com.au
          </a>{" "}
          for any inquiries.
        </small>{" "}
      </div>
    </div>
  );
};

export default SWAppHomepage;
