import { PartnerLogo } from "../utilities/Logo";

const year = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="container d-flex flex-column flex-md-row flex-wrap justify-content-between align-items-center">
      <div id="labour-hire" className="row my-3 my-md-0 bg-transparent">
        <div className="d-flex align-items-center justify-content-center ">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <PartnerLogo
              href={"https://www.palmscheme.gov.au/"}
              src={"./images/palm.png"}
            />
            <PartnerLogo
              href={"https://www.labourhire.qld.gov.au/"}
              src={"./images/labour-hire-2.jpg"}
            />
          </div>
        </div>
      </div>
      <div className="row my-3 my-md-0 text-center text-black">
        <small>Copyright &copy; {year} Tasmanian Berries Pty Ltd.</small>
      </div>
    </footer>
  );
};

export default Footer;
