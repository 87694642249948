import Map from "react-map-gl";

const CustomMap = (props) => {
  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoidHJ1bmdoYW5ndXllbjEzIiwiYSI6ImNsZmxzbjY5YTA0eHczc3BmNTg1cnRyZ3IifQ.O8AEdbxKnT9Io6tSfchQPg"
      initialViewState={{
        longitude: props.lon, //146.7288009
        latitude: props.lat, //-41.5479094
        zoom: props.z, //14
      }}
      style={{ width: "100%", height: 400 }}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
    />
  );
};

function OurSites(props) {
  return (
    <section id="sites" className="ftco-section bg-light">
      <div className="img-bg">
        <img
          src="./images/corner-map.png"
          alt="corner-farmhand"
          className="img-corner-right"
        />
      </div>
      <div className={props.reveal ? "container reveal" : "container"}>
        <div className="row justify-content-center mb-5 pb-3">
          <div className="col-md-7 text-center">
            <h2>Our Sites</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="site-block justify-content-end">
              <iframe
                title="exton-site"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d95551.56141267603!2d146.728816!3d-41.547894!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9e2d3926eade9af5!2sTasmanian%20Berries%20Exton%20site!5e0!3m2!1sen!2sau!4v1662528376671!5m2!1sen!2sau"
                width="100%"
                height="400"
                loading="lazy"
                alt="exton-site"
              ></iframe>
              {/* <CustomMap lon={146.7220926} lat={-41.5467706} z={13} /> */}
              <div className="text p-4 float-right d-block">
                <div className="topper d-flex align-items-center">
                  <div className="py-2 px-3 align-self-stretch">
                    <span className="site-name">Exton</span>
                  </div>
                </div>
                {/* <h3>Exton</h3> */}
                <p className="site-address">284 Exton Rd, Exton TAS 7303</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="site-block justify-content-end">
              <iframe
                title="ch-site"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d47823.941479345696!2d146.597096!3d-41.482679!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaa7a68ce9ee77115%3A0xa78d1b7f2e1b3393!2sChristmas%20Hills%20Raspberry%20Farm%20Cafe!5e0!3m2!1sen!2sau!4v1662528474123!5m2!1sen!2sau"
                width="100%"
                height="400"
                loading="lazy"
                alt="ch-site"
              ></iframe>
              {/* <CustomMap lon={146.5958642} lat={-41.478907} z={13} /> */}
              <div className="text p-4 float-right d-block">
                <div className="topper d-flex align-items-center">
                  <div className="py-2 px-3 align-self-stretch">
                    <span className="site-name">Christmas Hills</span>
                  </div>
                </div>
                {/* <h3>Christmas Hills</h3> */}
                <p className="site-address">
                  9 Christmas Hills Rd, Elizabeth Town TAS 7304
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default OurSites;
