import NavItem from "../utilities/NavItem";
import { TopLogo } from "../utilities/Logo";
import { ToggleButton, WorkWithUsButton } from "../utilities/Button";

function SecondaryNavbar(props) {
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark bg-blackberry sticky-top ftco-navbar py-3`}
    >
      <div className="container mx-lg-auto">
        <TopLogo />
        <ToggleButton />
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <NavItem href={"/#"} name={"Home"} />
            <NavItem
              active={props.berries}
              href={"/berries"}
              name={"Our Berries"}
            />
            <NavItem
              active={props.products}
              href={"/products"}
              name={"Our Products"}
            />
            <NavItem active={props.sites} href={"/sites"} name={"Our Sites"} />
            <NavItem href={"#contact"} name={"Contact Us"} />

            <li className="animate__animated ms-0 my-3 ms-lg-3">
              <WorkWithUsButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default SecondaryNavbar;
