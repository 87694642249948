import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="my-5">
        <h1>Privacy Policy</h1>
        <div>
          <p>
            This statement applies to the Tasmanian Berries' website only and
            not to any linked websites. If you visit any other site, we
            recommend you read their privacy statement.
          </p>
          <h2>Anonymous access</h2>
          <p>
            You can access and use our website anonymously, without disclosing
            your personal information.
          </p>
          <h2>Collection and use of personal information</h2>
          <p>
            This website does not collect your personal information, other than
            information you choose to provide through online forms or email
            addresses. We may use this to respond to you and discuss your
            enquiry.
          </p>
          <h2>Disclosure</h2>
          <p>
            We will not disclose to a third party any personal information you
            provide without your consent, unless authorised by law. In the event
            of an investigation into unlawful activity (such as hacking or
            abusive emails), a law enforcement or government agency may exercise
            its legal authority to inspect web server records.
          </p>
          <h2>Collection and use of site visit data</h2>
          <p>
            We analyse how our site is used to help us make it better. We do
            this by obtaining reports on usage from Google Analytics. These tell
            us which pages are visited, for how long, on what operating systems
            and browsers and from what areas.
          </p>
          <h2>Data security</h2>
          <p>
            Our employees are required to protect any personal information we
            collect. Our technology and work policies are designed to protect
            this information. However, if you send information over the Internet
            it may not be completely secure. If you are concerned about sending
            us sensitive information you might prefer to contact us by telephone
            or mail.
          </p>
          <h2>Any questions</h2>
          <p>
            If you have any questions about this statement or how we handle your
            personal information more generally, please{" "}
            <a href="#contact">contact us</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
