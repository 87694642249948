const PhotoDescription = () => {
  return (
    <div className="desc w-100 px-4">
      <div className="text w-100 mb-3">
        <span className="text">Seasonal Work at</span>
        <h2>
          <a href="/work-with-us#seasonal-work">Tasmanian Berries</a>
        </h2>
      </div>
    </div>
  );
};

const Photo = (props) => {
  return (
    <div className="col-md-4 p-0">
      <div
        className="gallery img d-flex align-items-end"
        style={{
          backgroundImage: props.src,
        }}
      >
        <PhotoDescription />
      </div>
    </div>
  );
};

function Gallery() {
  return (
    <section id="gallery" className="ftco-section bg-lightgray py-0 ">
      <div className="container-fluid">
        <div className="row">
          <Photo src={`url("images/gallery-1.jpg")`} />
          <Photo src={`url("images/gallery-2.jpg")`} />
          <Photo src={`url("images/gallery-3.jpg")`} />
          <Photo src={`url("images/gallery-4.jpg")`} />
          <Photo src={`url("images/gallery-5.jpg")`} />
          <Photo src={`url("images/gallery-6.jpg")`} />
          <Photo src={`url("images/gallery-7.jpg")`} />
          <Photo src={`url("images/gallery-8.jpg")`} />
          <Photo src={`url("images/gallery-9.jpg")`} />
        </div>
      </div>
    </section>
  );
}
export default Gallery;
