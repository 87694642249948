import { useState } from "react";
import { ContactInfo, BusinessHours, Social } from "../utilities/ContactList";
import { BottomLogo, SunshineLogo, PartnerLogo } from "../utilities/Logo";

function Contact(props) {
  const [showTopBtn, setShowTopBtn] = useState(false);

  // When the user scrolls down 500px from the top of the document, show the button
  // window.onscroll = function () {
  //   scrollFunction();
  // };

  window.addEventListener("scroll", scrollFunction);

  function scrollFunction() {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      setShowTopBtn(true);
    } else {
      setShowTopBtn(false);
    }
  }

  // When the user clicks on the button, scroll to the top of the document
  function topFunction() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <footer
      id="contact"
      className="ftco-footer ftco-section bg-blackberry border-top border-blackberry2"
    >
      <div style={{ display: showTopBtn ? "block" : "none" }}>
        <button
          onClick={topFunction}
          id="goToTopBtn"
          className="bg-transparent p-2 rounded-0 d-flex flex-column align-items-center justify-content-center"
          title="Go to top"
          style={{ fontSize: "0.5rem", zIndex: "9999" }}
        >
          <img
            src="./images/raspberry.png"
            alt="corner-farmhand"
            className="mb-1"
            style={{ width: "3rem" }}
          />
          <span
            className="border-1 p-1 border-gray border"
            style={{ color: "#C0C0C0" }}
          >
            BACK TO TOP
          </span>
        </button>
      </div>
      <div className="img-bg">
        <img
          src="./images/logo-secondary-no-text.png"
          alt="corner-farmhand"
          className="img-corner-bottom-right"
        />
      </div>
      <div
        id="footer-container"
        className={props.reveal ? "container reveal" : "container"}
      >
        <div className="row mb-4 mb-lg-3">
          <div className="col-md-6 col-lg-4 pe-md-4">
            <div className="ftco-footer-widget mb-4">
              <BottomLogo />
              <Social />
            </div>
          </div>
          <div className="col-md-6 col-lg-4 px-md-4 mt-md-4">
            <div className="ftco-footer-widget mb-4">
              <BusinessHours />
            </div>
            <div className="ftco-footer-widget mb-4">
              <ContactInfo />
            </div>
          </div>
          <div className="col-md-12 col-lg-4 ps-lg-4">
            <div className="ftco-footer-widget p-3 bg-blackberry border border-blackberry2 border-opacity-50 rounded shadow-lg">
              <SunshineLogo />
            </div>
          </div>
        </div>

        <div id="partners" className="row bg-blueberry mb-5 mb-lg-4 py-4">
          <div className="d-flex flex-wrap align-items-center justify-content-center ">
            <h4 className="p-2 my-2 mx-4">Our Partners</h4>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <PartnerLogo
                href={"https://www.primaryemployers.com.au/"}
                src={"./images/partners-pet.png"}
              />
              <PartnerLogo
                href={"http://www.driscolls.com.au/"}
                src={"./images/partners-driscolls-2.png"}
              />
              <PartnerLogo
                href={"http://www.fruitgrowerstas.org.au/"}
                src={"./images/partners-fruitgrowerstas.png"}
              />
              <PartnerLogo
                href={"http://www.elitetunnels.com/"}
                src={"./images/partners-elitetunnels.png"}
              />
              <PartnerLogo
                href={"http://www.haygrove.com/"}
                src={"./images/partners-haygrove.png"}
              />
              <PartnerLogo
                href={"https://berries.net.au/"}
                src={"./images/partners-berriesaustralia.png"}
              />
            </div>
          </div>
        </div>

        <div id="acknowledgements" className="row mb-5 mb-lg-4 py-5">
          <h4 className="text-center mb-4">Acknowledgements</h4>
          <div className="d-flex flex-column flex-lg-row gap-3 justify-content-center">
            <div className="d-flex flex-column p-3 bg-blackberry">
              <div className="">
                <img
                  src={"./images/acknowledgement_1.png"}
                  alt=""
                  srcSet=""
                  className="mb-3 acknowledgement_img"
                />
              </div>
              <small className="">
                Tasmanian Berries and Sunshine Berries would like to acknowledge
                the Traditional Custodians of the lands, skies and waterways on
                which we work and live. We pay our respects to them and their
                Elders past, present and emerging.
              </small>
            </div>
            <div className="d-flex flex-column p-3 bg-blackberry">
              <div className="">
                <img
                  src={"./images/acknowledgement_2.png"}
                  alt=""
                  srcSet=""
                  className="mb-3 acknowledgement_img "
                />
              </div>
              <small className="">
                Tasmanian Berries and Sunshine Berries values and promotes an
                inclusive workplace where everyone, from any background, can
                feel valued, be appreciated and do their best work. At our farms
                and accommodation sites, everyone regardless of gender identity
                or expression, sexual orientation, religion, ethnicity, age,
                neurodiversity, disability status, citizenship or any other
                aspect that makes them unique can contribute to improving the
                health and wellbeing of our community.
              </small>
            </div>
            <div className="d-flex flex-column p-3 bg-blackberry">
              <div className="">
                <img
                  src={"./images/sedex.jpg"}
                  alt=""
                  srcSet=""
                  className="mb-3 sedex_img "
                />
              </div>
              <small className="">
                As a Sedex member we are committed to being a responsible
                business, sourcing responsibly, and improving ethical standards
                and working conditions within the supply chain.
              </small>
            </div>
          </div>
        </div>
        {/* <div
          id="divider"
          className="d-flex align-items-center justify-content-center my-5 opacity-25"
        >
          <img
            src={"./images/divider.png"}
            alt=""
            srcSet=""
            className="divider_img"
          />
        </div> */}
      </div>
    </footer>
  );
}
export default Contact;
