import { Routes, Route } from "react-router-dom";

import Navbar from "./shared_components/Navbar";
import SecondaryNavbar from "./shared_components/SecondaryNavbar";
import Banner from "./shared_components/Banner";
import Contact from "./shared_components/Contact";
import Footer from "./shared_components/Footer";

import Loader from "./utilities/Loader";

import SWAppHomepage from "./webapp/SWAppHomepage";
import OurBerries from "./pages/OurBerries";
import OurProducts from "./pages/OurProducts";
import OurSites from "./pages/OurSites";
import Maps from "./pages/Maps";
import Gallery from "./pages/Gallery";
import WorkWithUs from "./pages/WorkWithUs";
import SeasonalWork from "./pages/SeasonalWork";
import PageNotFound from "./pages/PageNotFound";

// Redirect links
import Webapp from "./webapp/Webapp";
import RedirectDailyReportApp from "./webapp/RedirectDailyReportApp";
import RedirectEmailSignatureApp from "./webapp/RedirectEmailSignatureApp";
import RedirectSprayApp from "./webapp/RedirectSprayApp";
import RedirectSprayAppSSB from "./webapp/RedirectSprayAppSSB";
import RedirectDispatchApp from "./webapp/RedirectDispatchApp";
import RedirectAttendanceApp from "./webapp/RedirectAttendanceApp";
import RedirectFrozenJamApp from "./webapp/RedirectFrozenJamApp";
import RedirectReviewApp from "./webapp/RedirectReviewApp";
import RedirectLabourApp from "./webapp/RedirectLabourApp";
import RedirectSwEmailApp from "./webapp/RedirectSwEmailApp";
import RedirectVehicleBookingApp from "./webapp/RedirectVehicleBookingApp";
import RedirectHarvestReport from "./webapp/RedirectHarvestReport";
import RedirectProductOrder from "./webapp/RedirectProductOrder";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RedirectMTrackTimesheet from "./webapp/RedirectMTrackTimesheet";

function onReady(callback) {
  var intervalId = window.setInterval(function () {
    if (document.getElementsByTagName("body")[0] !== undefined) {
      window.clearInterval(intervalId);
      callback.call(this);
    }
  }, 500);
}

function setVisible(selector, visible) {
  document.querySelector(selector).style.display = visible ? "block" : "none";
}

onReady(function () {
  setVisible(".main-content", true);
  setVisible("#loader", false);
});

function Main() {
  return (
    <div className="main-content">
      <Navbar />
      <Banner />
      <OurBerries reveal={true} />
      <OurProducts reveal={true} />
      <OurSites reveal={true} />
      <Contact reveal={true} />
      <Footer />
    </div>
  );
}

function HomePage() {
  return (
    <>
      <Loader />
      <Main />
    </>
  );
}

function WorkWithUsPage() {
  return (
    <>
      <Loader />
      <div className="main-content">
        <SecondaryNavbar />
        <WorkWithUs />
        <SeasonalWork reveal={true} />
        <Gallery reveal={true} />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

function OurBerriesPage() {
  return (
    <>
      <SecondaryNavbar berries={true} />
      <OurBerries />
      <Gallery />
      <Contact />
      <Footer />
    </>
  );
}

function OurProductsPage() {
  return (
    <>
      <SecondaryNavbar products={true} />
      <OurProducts />
      <Contact />
      <Footer />
    </>
  );
}

function OurSitesPage() {
  return (
    <>
      <SecondaryNavbar sites={true} />
      <OurSites />
      <Contact />
      <Footer />
    </>
  );
}

function OurMapsPage() {
  return (
    <>
      <SecondaryNavbar sites={true} />
      <Maps />
      <Contact />
      <Footer />
    </>
  );
}

function PrivacyPolicyPage() {
  return (
    <>
      <SecondaryNavbar />
      <PrivacyPolicy />
      <Contact />
      <Footer />
    </>
  );
}

function NotFoundPage() {
  return (
    <>
      <SecondaryNavbar />
      <PageNotFound />
      <Contact />
      <Footer />
    </>
  );
}

function VehicleBookingAppPage() {
  return (
    <>
      <iframe
        title="vehicle-booking-app"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbxNsNtvA-oZhW34N0DFapqUXl6pIXILPY-XhAoZTqk7wXvSCIbdHZiESp2CcqOSMcU9/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function SW1AppPage() {
  return (
    <>
      <iframe
        title="sw-app-1"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbyQtj82gz5bYfpBq0z4GDeG86J_c5xTAW3M_Xnwdz51lSaHkfqKTI5xFTYTzzirDZWbFA/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function SW2AppPage() {
  return (
    <>
      <iframe
        title="sw-app-2"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbwEwGe0mg7_off0gsRnzgx9utqemR9i_X1Pd0KlNtgx1EXOAKdoUm6L_3CPWFjFYsfFNA/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function TASSprayAppPage() {
  return (
    <>
      <iframe
        title="spray-app"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbw961KjlEzYl_hpKLib1tszcGdWCOkIABmVzwOua69RIUWixyGncGGQUS4OOSJLDuXY/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function QLDSprayAppPage() {
  return (
    <>
      <iframe
        title="spray-app"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbzkG1OCWkiQwb9hyzsnRAIT5-UrphmiT2wkOtgjDLb-W7tnua-SURvCX3BN4FnY-eSa/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function ProductOrderPage() {
  return (
    <>
      <iframe
        title="product-order"
        src="https://script.google.com/a/macros/tasmanianberries.com.au/s/AKfycbw4R3h3kphvSI04zr7Mz-Bk-0TlaPnpHb76XK0X-pQare6vTN_DIKkEGOhK07179xA/exec"
        frameBorder={0}
        style={{
          overflow: "hidden",
          overflowX: "hidden",
          overflowY: "hidden",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        height="100%"
        width="100%"
      />
    </>
  );
}

function App() {
  return (
    <Routes>
      {/* Web Pages */}
      <Route path="/" element={<HomePage />} />
      <Route path="/berries" element={<OurBerriesPage />} />
      <Route path="/products" element={<OurProductsPage />} />
      <Route path="/sites" element={<OurSitesPage />} />
      <Route path="/maps" element={<OurMapsPage />} />
      <Route path="/work-with-us" element={<WorkWithUsPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

      {/* Web Apps */}
      <Route path="/app" element={<Webapp />} />

      {/* Email Signature */}
      <Route path="/app/harvest-report" element={<RedirectHarvestReport />} />

      {/* Email Signature */}
      <Route
        path="/app/email-signature"
        element={<RedirectEmailSignatureApp />}
      />

      {/* Seasonal Worker App */}
      <Route path="/sw" element={<SWAppHomepage />} />

      {/* Vehicle Booking App */}
      <Route
        path="/app/vehicle-booking"
        element={<RedirectVehicleBookingApp />}
      />

      {/* Product Order App */}
      <Route path="/app/product-order" element={<RedirectProductOrder />} />

      {/* Spray App */}
      <Route path="/app/spray" element={<RedirectSprayApp />} />
      <Route path="/app/spray2" element={<RedirectSprayAppSSB />} />

      <Route path="/app/tas-spray" element={<TASSprayAppPage />} />
      <Route path="/app/qld-spray" element={<QLDSprayAppPage />} />

      {/* Dispatch App */}
      <Route path="/app/dispatch" element={<RedirectDispatchApp />} />

      {/* Labour App */}
      <Route path="/app/labour" element={<RedirectLabourApp />} />

      {/* Attendance App */}
      <Route path="/app/attendance" element={<RedirectAttendanceApp />} />

      {/* SW Review App */}
      <Route path="/app/review" element={<RedirectReviewApp />} />

      {/* SW App */}
      <Route path="/app/sw1" element={<SW1AppPage />} />
      <Route path="/app/sw2" element={<SW2AppPage />} />

      {/* SW Email Collecting App */}
      <Route path="/app/sw-email" element={<RedirectSwEmailApp />} />

      {/* Daily Harvest Report */}
      <Route path="/app/daily-report" element={<RedirectDailyReportApp />} />

      {/* Frozen Jam */}
      <Route path="/app/frozen-jam" element={<RedirectFrozenJamApp />} />

      {/* defunct due to security concerns. See RedirectMTrackTimesheet.js for comment */}
      {/* MTrack Timesheet
      <Route path="/app/timesheet" element={<RedirectMTrackTimesheet />} /> */}

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
