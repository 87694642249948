function reveal() {
  const reveals = document.querySelectorAll(".reveal");
  const elementVisible = 0;
  for (let i = 0; i < reveals.length; i++) {
    const windowHeight = window.innerHeight;
    const elementTop = reveals[i].getBoundingClientRect().top;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    }
  }
}

window.addEventListener("scroll", reveal);
// To check the scroll position on page load
reveal();
