const TopLogo = () => {
  return (
    <a className="navbar-brand" href="/#">
      <img
        className="py-2"
        src="./images/logo-secondary-white-text-horizontal.png"
        alt="logo-top"
      />
    </a>
  );
};

const BottomLogo = () => {
  return (
    <>
      <img
        className="bottom-logo mb-3 border-0 rounded-0"
        src="./images/logo-secondary-white-text.png"
        alt="logo-bottom"
      />
      <p>We Value: Safety, Passion, Openness, Respect, and Teamwork</p>
    </>
  );
};

const SunshineLogo = () => {
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-row gap-3 align-items-end">
        <a
          href="https://sunshineberries.com.au/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="mb-3 border-0 rounded-0 sunshine-logo"
            src="./images/sb-logo-secondary-white-text.png"
            alt="logo-bottom"
          />
        </a>
        {/* <a
          href="https://www.labourhire.qld.gov.au/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="mb-3 border-0 rounded-0 sunshine-logo"
            src="./images/labour-hire-2.png"
            alt="logo-bottom"
          />
        </a> */}
      </div>
      <p>
        Sunshine Berries is a subsidiary of Tasmanian Berries. We are a family
        owned business commercially producing berries for Driscoll’s Australia,
        in Central Northern Tasmania and the Bundaberg Region in Queensland.
      </p>
      <p>
        {/* <u>Website:</u>{" "} */}
        <a
          href="https://sunshineberries.com.au/"
          target="_blank"
          rel="noreferrer"
          className="btn btn-sm btn-primary rounded-0"
        >
          www.sunshineberries.com.au
        </a>
      </p>
    </div>
  );
};

const PartnerLogo = (props) => {
  return (
    <a
      href={props.href}
      className="partner-logo"
      target="_blank"
      rel="noreferrer"
    >
      <img src={props.src} alt="" srcSet="" />
    </a>
  );
};
export { TopLogo, BottomLogo, SunshineLogo, PartnerLogo };
