import SeasonalPositions from "../utilities/SeasonalPositions";
import React, { useState } from "react";

// Firebase
import { doc, setDoc } from "firebase/firestore";
import { db } from "../services/firebaseConfig";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

const RECAPTCHA_KEY = "6LcVU2AkAAAAADT6faFqsHHd3PTeG79ok_hY-NqD";

function SeasonalWork(props) {
  const DEBUG = false;
  const [sentStatus, setSentStatus] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [verification, setVerification] = useState("");
  const timestamp = new Date();

  // Initialize Firebase

  const [token, setToken] = useState("");
  const verifyRecaptchaCallback = React.useCallback((token) => {
    setToken(token);
    DEBUG && console.log(token);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const randomId = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/;

    name
      ? document.getElementById("name-feedback").classList.add("d-none")
      : document.getElementById("name-feedback").classList.remove("d-none");

    email
      ? document.getElementById("email-feedback").classList.add("d-none")
      : document.getElementById("email-feedback").classList.remove("d-none");

    email.match(validRegex)
      ? document
          .getElementById("email-validation-feedback")
          .classList.add("d-none")
      : document
          .getElementById("email-validation-feedback")
          .classList.remove("d-none");

    subject
      ? document.getElementById("subject-feedback").classList.add("d-none")
      : document.getElementById("subject-feedback").classList.remove("d-none");

    message
      ? document.getElementById("message-feedback").classList.add("d-none")
      : document.getElementById("message-feedback").classList.remove("d-none");

    if (!verification) {
      if (name && email && email.match(validRegex) && subject && message) {
        DEBUG &&
          console.log({
            name: name,
            email: email,
            subject: subject,
            message: message,
            timestamp: timestamp,
          });
        setDoc(doc(db, "enquiry", randomId), {
          name: name,
          email: email,
          subject: subject,
          message: message,
          timestamp: timestamp,
        });
        setName("");
        setEmail("");
        setSubject("");
        setMessage("");
        setVerification("");
        setSentStatus(true);
      }
    }
  };

  return (
    <section
      id="seasonal-work"
      className="ftco-section-dark bg-blackberry bg-gradient overflow-hidden"
    >
      <div className="img-bg">
        <img
          src="./images/corner-farmhand.png"
          alt="corner-farmhand"
          className="img-corner-bottom-left"
        />
      </div>
      <div className={props.reveal ? "container reveal" : "container"}>
        <h2>Seasonal Work</h2>
        <div className="row ">
          <div className="col-lg-6">
            <p>
              Our picking season, from October to May each year, provides an
              opportunity for seasonal workers for both locals and working
              visitors to Australia.
            </p>
            <p>
              To work at Tasmanian Berries, we recommend that you are physically
              fit and motivated. The work, particularly harvest work, can be
              physically demanding, but very rewarding. If you are interested in
              harvest work at Tasmanian Berries, please register your interest
              below.
            </p>
            <div className="row">
              <h3>Enquiry</h3>
              <div className="col-md-12">
                <div className="pb-5">
                  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                    <form
                      action="?"
                      method="POST"
                      id="contactForm"
                      name="contactForm"
                      className="contactForm needs-validation"
                      noValidate
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group input-group">
                            <span className="input-group-text short d-flex align-items-center justify-content-center">
                              Name <span className="text-primary ms-1"> *</span>
                            </span>
                            <input
                              onChange={(e) => {
                                setName(e.target.value);
                                setSentStatus(false);
                                e.target.value
                                  ? document
                                      .getElementById("name-feedback")
                                      .classList.add("d-none")
                                  : document
                                      .getElementById("name-feedback")
                                      .classList.remove("d-none");
                              }}
                              type="text"
                              className="form-control"
                              name="name"
                              id="name"
                              value={name}
                              required
                            />
                          </div>
                          <div
                            id="name-feedback"
                            className="text-primary custom-invalid-feedback d-none"
                          >
                            <small>This is a required field.</small>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group input-group">
                            <span className="input-group-text short d-flex align-items-center justify-content-center">
                              Email{" "}
                              <span className="text-primary ms-1"> *</span>
                            </span>
                            <input
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setSentStatus(false);
                                e.target.value
                                  ? document
                                      .getElementById("email-feedback")
                                      .classList.add("d-none")
                                  : document
                                      .getElementById("email-feedback")
                                      .classList.remove("d-none");
                                document
                                  .getElementById("email-validation-feedback")
                                  .classList.add("d-none");
                              }}
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={email}
                              required
                            />
                          </div>
                          <div
                            id="email-feedback"
                            className="text-primary custom-invalid-feedback d-none"
                          >
                            <small>This is a required field.</small>
                          </div>
                          <div
                            id="email-validation-feedback"
                            className="text-warning custom-invalid-feedback d-none"
                          >
                            <small>Please enter a valid email address.</small>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group input-group">
                            <span className="input-group-text d-flex align-items-center justify-content-center">
                              Subject{" "}
                              <span className="text-primary ms-1"> *</span>
                            </span>
                            <input
                              onChange={(e) => {
                                setSubject(e.target.value);
                                setSentStatus(false);
                                e.target.value
                                  ? document
                                      .getElementById("subject-feedback")
                                      .classList.add("d-none")
                                  : document
                                      .getElementById("subject-feedback")
                                      .classList.remove("d-none");
                              }}
                              type="text"
                              className="form-control"
                              name="subject"
                              id="subject"
                              value={subject}
                              required
                            />
                          </div>
                          <div
                            id="subject-feedback"
                            className="text-primary custom-invalid-feedback d-none"
                          >
                            <small>This is a required field.</small>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group input-group">
                            <span className="input-group-text d-flex align-items-center justify-content-center">
                              Message{" "}
                              <span className="text-primary ms-1"> *</span>
                            </span>
                            <textarea
                              onChange={(e) => {
                                setMessage(e.target.value);
                                setSentStatus(false);
                                e.target.value
                                  ? document
                                      .getElementById("message-feedback")
                                      .classList.add("d-none")
                                  : document
                                      .getElementById("message-feedback")
                                      .classList.remove("d-none");
                              }}
                              name="message"
                              className="form-control"
                              id="message"
                              rows="5"
                              value={message}
                              required
                            />
                          </div>
                          <div
                            id="message-feedback"
                            className="text-primary custom-invalid-feedback d-none"
                          >
                            <small>This is a required field.</small>
                          </div>
                        </div>
                        <div className="col-md-12 d-none">
                          <div className="form-group">
                            <label className="label" htmlFor="verification">
                              Are you a robot?
                            </label>
                            <input
                              onChange={(e) => {
                                setVerification(e.target.value);
                                setSentStatus(false);
                              }}
                              type="text"
                              className="form-control"
                              name="verification"
                              id="verification"
                              value={verification}
                            />
                          </div>
                        </div>
                        <div className="col-md-12 my-3">
                          <div
                            className={
                              sentStatus
                                ? "alert alert-success d-flex align-items-center p-2"
                                : "d-none"
                            }
                            role="alert"
                          >
                            <div>
                              <span className="fa fa-check me-1"></span> Thank
                              you!
                            </div>
                          </div>
                          <GoogleReCaptcha onVerify={verifyRecaptchaCallback} />
                          <div className="form-group">
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className="btn btn-primary"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </GoogleReCaptchaProvider>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-5">
            <img
              src="./images/work-with-us-2.jpg"
              alt="work-with-us-2"
              className="mb-4"
            />
            <h3 id="apply-now" className="mb-4">
              Apply Now!
            </h3>
            <SeasonalPositions />
          </div>
        </div>
      </div>
    </section>
  );
}
export default SeasonalWork;
