const ContactInfo = () => {
  return (
    <>
      <h3>Contact Information</h3>
      <div className="ftco-footer-contact mb-3">
        <div className="contact-list">
          <div className="contact-list-item">
            <a href="tel:03 6362 2740">
              <span className="fa">P</span>
              <span>(03) 6362 2740</span>
            </a>
          </div>
          <div className="contact-list-item">
            <a href="mailto:admin@tasmanianberries.com.au">
              <span className="fa">E</span>
              <span>admin@tasmanianberries.com.au</span>
            </a>
          </div>
          <div className="contact-list-item">
            <a
              href="https://goo.gl/maps/UeAHqQVFhs7NdHXb9"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa">A</span>
              <span>284 Exton Rd, Exton TAS 7303</span>
            </a>
          </div>
          <div className="contact-list-item">
            <span className="fa">PA</span>
            <span>PO Box 275, Deloraine, Tasmania 7304</span>
          </div>
          <div className="contact-list-item">
            <span className="fa">ABN</span>
            <span>83 168 549 442</span>
          </div>
        </div>
      </div>
    </>
  );
};

const BusinessHours = () => {
  return (
    <>
      <h3 className="ftco-heading-2">Business Hours</h3>
      <div>
        <p>Monday - Friday : 8.00am - 4.00pm </p>
        {/* <p>8.00am - 4.00pm Monday - Friday (December and January only)</p> */}
      </div>
    </>
  );
};

const Social = () => {
  return (
    <>
      {/* <h3 className="ftco-heading-2 h4">Let's connect</h3> */}
      <ul className="ftco-footer-social list-unstyled">
        <li>
          <a
            href="https://www.facebook.com/tasberries"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fa fa-facebook"></span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/tasmanian-berries"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fa fa-linkedin"></span>
          </a>
        </li>
        <li>
          <a
            href="https://goo.gl/maps/UeAHqQVFhs7NdHXb9"
            target="_blank"
            rel="noreferrer"
          >
            <span className="fa fa-google"></span>
          </a>
        </li>
      </ul>
    </>
  );
};

export { ContactInfo, Social, BusinessHours };
