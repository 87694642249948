import PermanentPositions from "../utilities/PermanentPositions";

function WorkWithUs(props) {
  return (
    <section
      id="work-with-us"
      className="ftco-section px-sm-1rem py-0 bg-light overflow-hidden"
    >
      <div className={props.reveal ? "container reveal" : "container"}>
        <div className="row">
          <div className="col-lg-6 d-flex">
            <div className="img d-flex align-self-stretch align-items-end align-items-lg-center justify-content-center justify-content-lg-end"></div>
          </div>
          <div className="col-lg-6 ps-lg-5 py-lg-5">
            <div className="img-bg">
              <img
                src="./images/logo-new-no-text-no-outline.png"
                alt="corner-farmhand"
                className="img-corner-bottom-right"
              />
            </div>
            <div className="row py-5">
              <div className="col-lg-12">
                <div className="fw-bold">Come Work With Us</div>
                <h2 className="mb-4">Open Positions</h2>
                <p>
                  Tasmanian Berries is an innovative and ever-expanding business
                  in Central, Northern Tasmania. The business has two farm
                  sites, both near Deloraine in Tasmania and provides employment
                  opportunities to a range of different skill sets and levels of
                  experience.
                </p>
                <p>
                  Tasmanian Berries provides opportunities for a range of
                  long-term career paths. If you wish to apply for a position,
                  we encourage you to contact us at{" "}
                  <span className="text-primary">
                    recruitment@tasmanianberries.com.au
                  </span>
                  .
                </p>
                <p>
                  We employ a range of both experienced and entry level farm
                  positions including machinery operators, truck drivers,
                  supervisors, workshop staff, and maintenance roles. We provide
                  learning and development opportunities for those that are
                  wanting to upskill and have career progression in the berry
                  industry.
                </p>
                <p>
                  Our motivated technical and professional teams continue to
                  grow. We employ those in IT, admin, HR and WHS, finance, and
                  data.
                </p>
                <p>
                  Any expressions of interest for Horticulture or Agriculture
                  Science based roles are encouraged. We are actively recruiting
                  for people passionate about irrigation, horticulture, or
                  agronomy. We would like to hear from both graduates and
                  experienced professionals.
                </p>
                <h3 id="apply-now" className="mt-5 mb-4">
                  Apply Now!
                </h3>
                <PermanentPositions />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default WorkWithUs;
