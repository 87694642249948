import React, { useState, useEffect } from "react";
import { auth } from "../services/firebaseConfig";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";

const WebappContainer = (props) => {
  return (
    <>
      <div className="mb-3">
        <h2 className="text-center m-0">
          <i className="fa fa-user me-3" aria-hidden="true"></i>
          {props.userName}
        </h2>
        <p className="text-center">({props.userEmail})</p>
      </div>
      {/* <div className="d-flex align-items-center justify-content-center mb-5 mx-3">
        <div className="d-flex flex-wrap align-items-center gap-2 gap-3 p-3 rounded">
          <div className="me-3">
            <img
              src="./images/logo-secondary-white-text.png"
              alt="logo"
              style={{ width: "70px" }}
            />
          </div>
          <div className="d-flex flex-column">
            <div className="fs-2">
              <i className="fa fa-user me-2" aria-hidden="true"></i>
              {props.userName}
            </div>
            <div className="">
              <i className="fa fa-envelope me-2" aria-hidden="true"></i>
              {props.userEmail}
            </div>
          </div>
        </div>
      </div> */}
      <div className="w-100">
        <div className="row px-4">
          <div className="col-12 col-md-6 col-xl-4 mb-5 px-0 px-md-2">
            <div className="border rounded shadow">
              <h3 className="text-bg-blueberry bg-gradient text-center mb-3">
                Resources
              </h3>
              <ul style={{ "list-style-type": "none" }}>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.fruitgrowerstas.org.au/induction-videos/"
                  >
                    <i class="fa fa-youtube-play me-2" aria-hidden="true"></i>{" "}
                    Induction Video
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/watch?v=zvz3k1gBg9A&ab_channel=TasmanianBerries"
                  >
                    <i class="fa fa-youtube-play me-2" aria-hidden="true"></i>{" "}
                    Attendance App Instructions
                  </a>
                </li>

                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/watch?v=VQqmJV2LWPY&ab_channel=TasmanianBerries"
                  >
                    <i class="fa fa-youtube-play me-2" aria-hidden="true"></i>{" "}
                    Email Signature Instructions
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://noble-canary-46f.notion.site/How-to-use-Sunshine-Berries-email-accounts-87691fe54ee7447cbde4bf9407ffbb6a"
                  >
                    <i class="fa fa-youtube-play me-2" aria-hidden="true"></i>{" "}
                    Sunshine Berries Email Instructions
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://noble-canary-46f.notion.site/Google-Drive-Instructions-f4a3ff0739cd41b4b4040b4e983466b0?pvs=4"
                  >
                    <i class="fa fa-youtube-play me-2" aria-hidden="true"></i>{" "}
                    Google Drive Instructions
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-5 px-0 px-md-2">
            <div className="border rounded shadow">
              <h3 className="text-bg-raspberry bg-gradient text-center mb-3">
                Webapp
              </h3>
              <ul style={{ "list-style-type": "none" }}>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tasmanianberries.com.au/app/daily-report"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i> Daily
                    Report
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tasmanianberries.com.au/app/dispatch"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i>{" "}
                    Dispatch
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tasmanianberries.com.au/app/frozen-jam"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i> Frozen
                    Jam
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSfcW3gUtSyVDbJSrJ6R7ASW5htRlra__k8P4BD83IsxQliaFQ/viewform"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i> Visy
                    Receive
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSerMM15zDk-dxfkOxmAobPczwbIGri8xTQUi1dB6lJ25ZeCZQ/viewform"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i>{" "}
                    Multisteps Receive
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/product-order"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i>{" "}
                    Product Order
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSejtyHrfwKVr8IdT-P7i7jyqmebVgGtuu-Ijm5e8PRoqFAucA/viewform?usp=sf_link"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i> 15min
                    Check
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.tasmanianberries.com.au/OldWebsite/webapp/calibration/index.html"
                  >
                    <i class="fa fa-barcode me-2" aria-hidden="true"></i> Daily
                    Calibration
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc728cf33G49Q_rI73ZokTbBsxQDBLDOg4iNAVCOcqXPoho6g/viewform"
                  >
                    <i class="fa fa-star me-2" aria-hidden="true"></i> In Field
                    QC Score Form
                  </a>
                </li>
                <hr />
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/attendance"
                  >
                    <i class="fa fa-users me-2" aria-hidden="true"></i>{" "}
                    Attendance
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/review"
                  >
                    <i class="fa fa-users me-2" aria-hidden="true"></i> Picker
                    Review
                  </a>
                </li>
                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/sw-email"
                  >
                    <i class="fa fa-users me-2" aria-hidden="true"></i> SW Email
                    Collecting App
                  </a>
                </li> */}
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/sw"
                  >
                    <i class="fa fa-users me-2" aria-hidden="true"></i> Seasonal
                    Worker App
                  </a>
                </li>
                <hr />
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/tas-spray"
                  >
                    <i class="fa fa-bug me-2" aria-hidden="true"></i> Spraying
                    App - TAS
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/qld-spray"
                  >
                    <i class="fa fa-bug me-2" aria-hidden="true"></i> Spraying
                    App - QLD
                  </a>
                </li>
                <hr />
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/email-signature"
                  >
                    <i class="fa fa-envelope me-2" aria-hidden="true"></i> Email
                    Signature Generator
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://tasmanianberries.com.au/app/vehicle-booking"
                  >
                    <i class="fa fa-car me-2" aria-hidden="true"></i> Vehicle
                    Booking App
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-5 px-0 px-md-2">
            <div className="border rounded shadow">
              <h3 className="text-bg-success bg-gradient text-center mb-3">
                Google Sheet
              </h3>
              <ul style={{ "list-style-type": "none" }}>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/u/0/?tgif=d"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>{" "}
                    Google Sheet
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1Yph4N8cytiftr9Ia3w8xaYA6djqMwqexKRvjGPfsMAY/edit?usp=sharing"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>{" "}
                    Dispatch
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1V9L5-vaOet_GZ4PDv4tRgAtow9A1FieXprXRU-NQwG0/edit#gid=803581912"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>{" "}
                    Frozen Jam
                  </a>
                </li>
                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/14VZ1l781ArgXGmdh6llJynTyelb22BsMeQIG6iDObnE/edit#gid=981578595"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i> QC
                    score
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1JenTsyBybs2mPaOWtTYsuEYf7U8F7MviuEzJ9GDV0Ik/edit#gid=1003321223"
                  >
                    Dayoff sheet
                  </a>
                </li> */}

                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1efPTJLWqHqPxjXe2y4zz_hKUY7a7dIPxDOsJ1bW8EDg/edit#gid=932721941"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>{" "}
                    Product Order
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1xZFJy39faYhMzONpNZUhAHIJAFaBW6d-wEwcUoSuvZ0/edit#gid=559128971"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i> 15min
                    Check
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1cHOE-O9lmeT7gWhyQGJSniYfchzUW0iw0Qm992MGAl0/edit#gid=1817852019"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i> Daily
                    Calibration
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1JfHAfALFWXvC-Hv_4yYgQurj03SWLlC30-ea-nrDECs/edit?resourcekey#gid=1751735778"
                  >
                    <i class="fa fa-star me-2" aria-hidden="true"></i>
                    In Field QC Score
                  </a>
                </li>
                <hr />
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1jaIK7dGo3zzjkL8c-rBIuw3BZeCWNfQrGqOr7sTjNtA/edit#gid=668808820"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>
                    Stocktake - TAS
                  </a>
                </li>
                <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1wNH9AzCRvIAxxt2dXiVBj-y22PgXDlnBg1wfOSlTAlY/edit#gid=668808820"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i>
                    Stocktake - QLD
                  </a>
                </li>

                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1HgugigKfdDPj-vAR4_eAdV7FXUhnI1gJEDWh_FsgWQ0/edit#gid=0"
                  >
                    <i class="fa fa-database me-2" aria-hidden="true"></i> SW
                    Email Collecting
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/13ERTNbSBnRsiRcyP1H0aPQctsLuy1tHmM3O9XkBLgPg/edit#gid=2109727547"
                  >
                    Picker List
                  </a>
                </li> */}

                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1g14hgyiKIRkZfY9WC3j3LmeTumRVMDeYtFTcJyrB98k/edit#gid=301614683"
                  >
                    Irrigation Monitoring
                  </a>
                </li> */}
                {/* <li>
                  <a
                    className="btn btn-outline-dark border-0 mb-2"
                    target="_blank"
                    rel="noreferrer"
                    href="https://docs.google.com/spreadsheets/d/1Zr2FxcTMtVCjT93BUVAlu6iVC89O36JNR_0M66tnCxg/edit#gid=1337461860"
                  >
                    Incident Report
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Webapp = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function (authResult) {
        console.log(authResult);
        console.log(authResult.user.email);
        console.log(authResult.user.displayName);
        document
          .getElementById("firebaseui-auth-container")
          .classList.add("d-none");

        if (authResult.user.email.includes("@tasmanianberries.com.au")) {
          setLoggedIn(true);
          setUserEmail(authResult.user.email);
          setUserName(authResult.user.displayName);
        } else {
          document.getElementById("no-access").classList.remove("d-none");
        }

        return true;
      },
      uiShown: function () {},
    },
    signInFlow: "popup",
    signInSuccessUrl: "",
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  };

  useEffect(() => {
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  return (
    <div className="fluid-container min-vh-100 d-flex flex-column justify-content-between align-items-center">
      <div className="w-100 text-bg-dark bg-gradient mb-5 py-3 shadow">
        <h1 className="text-center h2 m-0">Tasmanian Berries</h1>
      </div>
      <div
        id="firebaseui-auth-container"
        className="d-flex flex-column align-items-center justify-content-center"
      >
        <h2 className="text-center">Welcome</h2>
        <div className="text-center px-5">
          Please sign in using your <strong>Tasmanian Berries</strong> email
          account.
        </div>
      </div>
      <div
        id="no-access"
        className="d-flex flex-column align-items-center justify-content-center d-none"
      >
        <div className="alert" role="alert">
          <i className="fa fa-exclamation-circle me-2" aria-hidden="true"></i>{" "}
          You have no access to this page!
        </div>
      </div>
      {loggedIn ? (
        <WebappContainer userName={userName} userEmail={userEmail} />
      ) : (
        <></>
      )}
      <div className="text-center p-3 border-top">
        <small>
          Please contact{" "}
          <a href="mailto:ha.nguyen@tasmanianberries.com.au">
            ha.nguyen@tasmanianberries.com.au
          </a>{" "}
          for any inquiries.
        </small>
      </div>
    </div>
  );
};

export default Webapp;
