// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyBe06brsljq-H3oFDN4clkedZQyJYmHwRc",
  authDomain: "tasberries-website.firebaseapp.com",
  projectId: "tasberries-website",
  storageBucket: "tasberries-website.appspot.com",
  messagingSenderId: "739372331058",
  appId: "1:739372331058:web:4aa78b3a165d11dc79cc2b",
  measurementId: "G-X4DWZS914R",
  databaseURL:
    "https://tasberries-website.australia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LcVU2AkAAAAADT6faFqsHHd3PTeG79ok_hY-NqD"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

export default app;
